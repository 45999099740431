/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useCallback, useMemo} from 'react'
import {chipClasses, filledInputClasses, inputLabelClasses, outlinedInputClasses} from '@mui/material'
import MUIAutocomplete, {
    autocompleteClasses,
    AutocompleteProps as MUIAutocompleteProps,
    AutocompleteRenderInputParams as MUIAutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete'
import {inputBaseClasses, InputBaseProps} from '@mui/material/InputBase'
import cn from 'classnames'
import {isEmpty, isNil} from 'lodash-es'

import {styled} from '@waybridge/wui'
import {ChevronArrowDownIcon} from '@waybridge/wui/Icons'
import TextField, {TextFieldProps} from '@waybridge/wui/TextField'

export type {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteValue,
    FilterOptionsState,
} from '@mui/base'
export {createFilterOptions} from '@mui/material/Autocomplete'

const AutocompleteTextField = styled(TextField)((props) => ({
    '.MuiAutocomplete-endAdornment': {
        marginRight: -2,
    },
    [`.${inputBaseClasses.root}.${inputBaseClasses.adornedStart}`]: {
        gap: props.variant === 'outlined' ? 0 : 6,
    },
    [`.${filledInputClasses.root}:not(.${filledInputClasses.sizeSmall})`]: {
        paddingTop: '11px',
    },
    [`.${inputLabelClasses.root}.${inputLabelClasses.filled}.${inputLabelClasses.sizeSmall}:not(.${inputLabelClasses.shrink})`]:
        {
            top: '-3px',
        },
    [`.${outlinedInputClasses.root}:not(.${outlinedInputClasses.sizeSmall}) .${inputBaseClasses.input}.${outlinedInputClasses.input}`]:
        {
            padding: `${props.label ? '15px' : '10px'} 8px 10px 12px`,
        },

    [`.${outlinedInputClasses.root}.${outlinedInputClasses.sizeSmall} input.${autocompleteClasses.input}.${inputBaseClasses.input}.${outlinedInputClasses.input}`]:
        {
            padding: '9px 12px',
        },
    [`.${filledInputClasses.root}`]: {
        paddingTop: '13px',
    },
    [`.${inputLabelClasses.root}.${inputLabelClasses.filled}.${inputLabelClasses.sizeSmall}`]: {
        top: '-3px',
    },
    [`${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.sizeSmall}`]: {
        padding: '9px 12px',
    },

    [`.${chipClasses.root}`]: {
        ...(props.variant !== 'outlined' && props.size === 'small'
            ? {
                  paddingTop: 0,
                  paddingBottom: 0,
              }
            : {}),
        ...(props.variant === 'outlined'
            ? {
                  marginBottom: props.size === 'small' ? 2 : 6,
                  marginTop: props.size === 'small' ? 2 : 6,
                  marginRight: 6,
              }
            : {marginRight: 6}),
    },

    [`.${inputBaseClasses.adornedEnd} .MuiAutocomplete-clearIndicator`]: {
        marginRight: 0,
        marginLeft: 0,
    },
    [`.${inputBaseClasses.adornedEnd} .MuiAutocomplete-popupIndicator`]: {
        marginRight: 0,
        marginLeft: 0,
    },
    [`&.AutocompleteTextField-noPadding .${inputBaseClasses.adornedStart}`]: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}))

export type AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = Omit<MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
    renderInput?: (params: MUIAutocompleteRenderInputParams) => React.ReactNode
    label?: string
    placeholder?: string
    /** a placeholder to show only when the field has no selected value */
    emptyPlaceholder?: string
    variant?: 'standard' | 'outlined' | 'filled'
    InputProps?: Partial<InputBaseProps>
    endAdornment?: React.ReactNode
    // Used as the key for list items created through `renderOption` if `renderOption` is not provided.
    keyName?: string
    TextFieldProps?: Partial<TextFieldProps>
    noInputPadding?: boolean
}

/**
 * The autocomplete is a normal text input enhanced by a panel of suggested options.
 */
export const Autocomplete = <
    T extends Record<string, any>,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    className,
    label,
    placeholder,
    emptyPlaceholder,
    InputProps,
    endAdornment,
    noInputPadding = false,
    variant = 'outlined',
    renderInput,
    keyName = 'value',
    renderOption: renderOptionProp,
    TextFieldProps,
    groupBy,
    options: _options,
    ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
    const {getOptionLabel} = props
    const renderOption = useCallback(
        (optionProps: any, option: T, state: AutocompleteRenderOptionState, ownerState: any) =>
            renderOptionProp?.(optionProps, option, state, ownerState) ?? (
                <li {...optionProps} key={option[keyName] ?? optionProps?.key}>
                    {getOptionLabel?.(option) ?? option?.label ?? option}
                </li>
            ),
        [getOptionLabel, renderOptionProp, keyName],
    )
    // You can group the options with the `groupBy` prop. If you do so, make sure that the options
    // are also sorted with the same dimension that they are grouped by, otherwise you will notice
    // duplicate headers. (https://mui.com/components/autocomplete/#grouped)
    const options = useMemo(() => {
        if (groupBy && getOptionLabel) {
            const getGroupedOptionLabel = (opt: T) => `${groupBy(opt)} - ${getOptionLabel(opt)}`

            return [..._options].sort((a, b) => getGroupedOptionLabel(a).localeCompare(getGroupedOptionLabel(b)))
        }
        return _options
    }, [groupBy, getOptionLabel, _options])

    return (
        <MUIAutocomplete<T, Multiple, DisableClearable, FreeSolo>
            {...props}
            className={cn('Autocomplete', className)}
            groupBy={groupBy}
            options={options}
            popupIcon={<ChevronArrowDownIcon />}
            renderInput={
                renderInput ||
                ((params) => (
                    <AutocompleteTextField
                        className={cn('AutocompleteTextField', className, {
                            'AutocompleteTextField-noPadding': noInputPadding,
                            'AutocompleteTextField-withPadding': !noInputPadding,
                        })}
                        label={label}
                        placeholder={placeholder ?? (isEmpty(props.value) ? emptyPlaceholder : undefined)}
                        variant={variant}
                        {...params}
                        {...TextFieldProps}
                        InputProps={{
                            ...params.InputProps,
                            ...InputProps,
                            endAdornment: isNil(endAdornment) ? (
                                params.InputProps.endAdornment
                            ) : (
                                <>
                                    {endAdornment}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        fullWidth
                    />
                ))
            }
            renderOption={renderOption}
        />
    )
}

export default Autocomplete
